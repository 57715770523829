import { useState, useContext, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { buildEmptyErrorState, buildErrorState } from "../helper/formError";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import { uniqueId } from "lodash";
import { FeedbackContext } from "../../feedback/FeedbackContext";
import CreateIcon from "@mui/icons-material/Create";
import { Theme } from "@emotion/react";
import { Autocomplete, Box, FormControl, SxProps } from "@mui/material";
import { DatePicker } from "../forms/DatePicker";
import GenderSelect, { GENDER } from "../forms/GenderSelect";
import { CountrySelect } from "../forms/CountrySelect";
import { CountryContext } from "../../country/CountryContext";
import { CandidateContext } from "../../candidate/CandidateContext";
import { count } from "console";
import { date_hr } from "../helper/datetime";

export type EditableEntryPropsTypeT =
  | "text"
  | "number"
  | "date"
  | "gender"
  | "nationality"
  | "schoolGraduation";

export interface EditableEntryPropsI {
  id: string;
  value: number | string | Date | number[];
  setValue: (i: number | string | Date | number[] | null) => void;
  onClickAway?: () => void;
  multiline?: boolean;
  type?: EditableEntryPropsTypeT;
  isObligatory?: boolean;
  setError?: (b: boolean) => void;
  label?: string;
  maxLength?: number;
  showIcon?: boolean;
  checkDup?: (newEmail: string) => string;
  checkContent?: (str: string) => string;
  sx?: SxProps<Theme>;
  minRows?: number;
  maxRows?: number;
  placeHolder?: string;
  placeHolderSX?: SxProps<Theme>;
  placeHolderClassName?: string;
  disabled?: boolean;
  dataTestId?: string;
}

export const EditableEntry = (props: EditableEntryPropsI) => {
  const [showEdit, setShowEdit] = useState(false);
  const [errorHere, setErrorHere] = useState(false);
  const [errorState, setErrorState] = useState(
    buildEmptyErrorState(["actField"])
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { closeOpenModal, setCloseOpenModal } = useContext(FeedbackContext);

  const { countryList } = useContext(CountryContext);
  const { schoolGraduationList } = useContext(CandidateContext);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (props.disabled) return;
    setAnchorEl(event.currentTarget);
    setShowEdit(!showEdit);
  };

  const onClickAway = () => {
    if (errorHere) return;
    setAnchorEl(null);
    setShowEdit(!showEdit);
    props.onClickAway && props.onClickAway();
  };
  // falls auf "Save" geklickt wird, während das Modal noch offen ist,
  // wird es dadurch geschlossen
  useEffect(() => {
    if (closeOpenModal) {
      setShowEdit(false);
      setAnchorEl(null);
      setCloseOpenModal(false);
    }
  }, [closeOpenModal, setCloseOpenModal]);

  const typeProps: { type?: string } = {};
  if (props.type === "number") typeProps.type = "number";

  return (
    <>
      <Typography
        className="editable-entry"
        variant="body2"
        id={uniqueId("EditableEntry")}
        onClick={handleClick}
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          maxHeight: 40,
          maxWidth: 300,
        }}
        data-testid={props.dataTestId}
      >
        {props.value && props.type === "date"
          ? date_hr(props.value as Date).toString()
          : props.type === "gender"
          ? GENDER[props.value as string]
          : props.value?.toString()}
        {((!props.value && props.value !== 0) ||
          (props.type === "nationality" &&
            Array.isArray(props.value) &&
            props.value.length == 0)) && (
          <span>
            {""}
            {props.placeHolder ? (
              <Box
                component="span"
                className={props.placeHolderClassName || ""}
                sx={props.placeHolderSX || {}}
              >
                {props.placeHolder}
              </Box>
            ) : (
              "-"
            )}{" "}
            {props.showIcon && <CreateIcon />}
          </span>
        )}
      </Typography>

      {anchorEl && (
        <Popover
          open={showEdit}
          onClose={onClickAway}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          // sx={{ height: "200px" }}
          PaperProps={{
            style: { width: "400px" },
          }}
        >
          {props.type === "date" ? (
            <FormControl
              sx={{ width: "90%", m: 2, ...(props.sx ? props.sx : {}) }}
            >
              <DatePicker
                id="dateBirth"
                label={props.label}
                value={props.value as Date}
                setValue={(v: Date | null) => props.setValue(v)}
                size="medium"
              />
            </FormControl>
          ) : props.type === "gender" ? (
            <FormControl
              sx={{ width: "90%", m: 2, ...(props.sx ? props.sx : {}) }}
            >
              <GenderSelect
                id="gender"
                label={props.label}
                value={props.value as string}
                setValue={(v) => props.setValue(v)}
                size="medium"
              />
            </FormControl>
          ) : props.type === "schoolGraduation" ? (
            <FormControl
              sx={{ width: "90%", m: 2, ...(props.sx ? props.sx : {}) }}
            >
              <Autocomplete
                id="schoolGraduations"
                freeSolo
                options={schoolGraduationList.map((l) => l.name)}
                sx={{ height: "47px" }}
                renderInput={(params) => (
                  <TextField
                    ref={params.InputProps.ref}
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        height: "47px",
                      },
                    }}
                    label="Schulabschluss"
                  />
                )}
                value={props.value as string}
                onChange={(e, v) => {
                  props.setValue(v);
                }}
                onInputChange={(e, v) => {
                  props.setValue(v);
                }}
              />
            </FormControl>
          ) : props.type === "nationality" ? (
            <FormControl
              sx={{ width: "90%", m: 2, ...(props.sx ? props.sx : {}) }}
            >
              <Autocomplete
                id="nationality"
                options={countryList.map((l) => l.name)}
                sx={{ height: "47px" }}
                renderInput={(params) => (
                  <TextField
                    ref={params.InputProps.ref}
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        height: "47px",
                      },
                    }}
                    label="Staatsangehörigkeit"
                  />
                )}
                value={props.value as string}
                onChange={(e, v) => {
                  props.setValue(v);
                }}
                onInputChange={(e, v) => {
                  props.setValue(v);
                }}
              />
            </FormControl>
          ) : (
            <TextField
              sx={{ width: "90%", m: 2, ...(props.sx ? props.sx : {}) }}
              inputProps={{
                style: { fontSize: "0.9em" },
                maxLength: props.maxLength || 255,
              }}
              id={props.id}
              autoFocus
              label={props.label}
              {...(props.minRows ? { minRows: props.minRows } : {})}
              {...(props.maxRows ? { maxRows: props.maxRows } : {})}
              ref={(ref) => ref && ref.focus()}
              {...(props.type === "number"
                ? {}
                : {
                    onFocus: (e) =>
                      e.currentTarget.setSelectionRange(
                        e.currentTarget.value.length,
                        e.currentTarget.value.length
                      ),
                  })}
              value={
                props.type === "number"
                  ? (props.value as unknown as number)
                  : props.value
                  ? props.value
                  : ""
              }
              onChange={(e) => {
                if (props.isObligatory) {
                  const error = e.target.value ? false : true;
                  setErrorState((p) => buildErrorState(p, "actField", error));
                  setErrorHere(error);
                  props.setError && props.setError(error);
                }
                if (props.checkContent) {
                  const errorStr = props.checkContent(e.target.value);
                  if (errorStr) {
                    setErrorState((p) =>
                      buildErrorState(p, "actField", true, errorStr)
                    );
                    setErrorHere(true);
                    props.setError && props.setError(true);
                  }
                }
                if (props.checkDup) {
                  const errorStr = props.checkDup(e.target.value);
                  const error = Boolean(errorStr);
                  setErrorState((p) =>
                    buildErrorState(p, "actField", error, errorStr)
                  );
                  setErrorHere(error);
                  props.setError && props.setError(error);
                }
                props.type === "number"
                  ? props.setValue(e.target.value as unknown as number)
                  : props.setValue(e.target.value);
              }}
              {...typeProps}
              multiline={props.multiline}
              error={errorState.actField?.error}
              helperText={
                errorState.actField?.error
                  ? errorState.actField?.helperText || "Bitte ausfüllen"
                  : ""
              }
            />
          )}
        </Popover>
      )}
    </>
  );
};
