import { isArray } from "lodash";
import { get, post, put, del, CallApiResultT } from "../shared/lib/api";
import { TestModusQuestionDataT, TestModusQuestionT } from "../testModus/api";
import { ClickMatrixMappingT } from "../testModus/questionTypes/QuestionClickMatrix";
import { DnDMappingT } from "../testModus/questionTypes/QuestionDnD";
import { checkApiResult } from "../shared/lib/checkApi";
import {
  data2professions,
  ProfessionDataT,
  ProfessionT,
} from "../profession/api";

const APIPATH = "/candidate";

export interface CorrectCountT {
  countCorrect: number;
  countTotal: number;
  sumPoints: number;
  sumPointsCorrect: number;
}

export interface MainResultDataT extends CorrectCountT {
  mainCategoryId: number;
  mainCategoryName: string;
  subCategories: SubResultDataT[];
  mainCategoryTarget: number;
  time: number;
  concentration: boolean;
}

export interface SubResultDataT extends CorrectCountT {
  subCategoryId: number;
  subCategoryName: string;
  questions: QuestionResultsDataT[];
  time: number;
}

export interface CandidateResultsDataT extends CorrectCountT {
  mainCategories: MainResultDataT[];
  concentrationPoints: number;
  concentrationPointsCorrect: number;
  concentrationTotal: number;
  concentrationCorrect: number;
  professionTarget: number;
}

export interface MainResultT extends CorrectCountT {
  mainCategoryId: number;
  mainCategoryName: string;
  mainCategoryTarget: number;
  subCategories: SubResultT[];
  time: number;
  concentration: boolean;
}

export interface SubResultT extends CorrectCountT {
  subCategoryId: number;
  subCategoryName: string;
  questions: QuestionResultsT[];
  time: number;
}

export interface CandidateResultsT extends CorrectCountT {
  mainCategories: MainResultT[];
  concentrationPoints: number;
  concentrationPointsCorrect: number;
  concentrationTotal: number;
  concentrationCorrect: number;
  professionTarget: number;
}

export interface QuestionResultsDataT
  extends Omit<
    TestModusQuestionDataT,
    | "noInSubCategory"
    | "mainCategoryDesc"
    | "mainCategoryImg"
    | "subCategoryDesc"
    | "comment"
  > {
  professionId: number;
  correct: string;
  correctDecoded: string[]; // is converted to number[]
  points: number;
  pointsCorrect: number;
  countTotal: number;
  countCorrect: number;
  answerDecoded?: ClickMatrixMappingT | DnDMappingT;
  candidateAnswer: string;
  candidateAnswerDecoded?:
    | DnDMappingT
    | ClickMatrixMappingT
    | string[]
    | string;
}

export interface CandidateLogsDataT {
  type: string;
  createdAt: string;
}

export interface MailLogsT {
  candidateId: number;
  mailTemplateId: number;
  createdAt: string | Date;
}

export interface CandidateLogsT {
  type: string;
  createdAt: Date;
}

export type CandidateDataT = {
  id: number;
  customerId: number;
  professionId?: number;
  professionName?: string;
  professionTarget?: number;
  professionExpiredAt?: string;
  testId?: string;
  testDate?: string;
  email: string;
  firstname: string;
  lastname: string;
  gender: string;
  uuid: string;
  street: string;
  streetno: string;
  zipcode: string;
  city: string;
  country: string;
  phone: string;
  createdAt: string;
  nationality?: string;
  schoolType?: string;
  schoolGraduation?: string;
  dateBirth?: string;
  comment?: string;
  results?: CandidateResultsDataT;
  logs?: CandidateLogsDataT[];
  mailLogs?: MailLogsT[];
  testPending?: boolean;
  candidateSettings?: any;
};

export interface QuestionResultsT
  extends Omit<
    TestModusQuestionT,
    | "noInSubCategory"
    | "mainCategoryDesc"
    | "mainCategoryImg"
    | "subCategoryDesc"
    | "comment"
  > {
  professionId: number;
  correct: string;
  correctDecoded: number[];
  points: number;
  answerDecoded?: DnDMappingT | ClickMatrixMappingT | string;
  candidateAnswer: string;
  candidateAnswerDecoded?:
    | DnDMappingT
    | ClickMatrixMappingT
    | string[]
    | string;
  countTotal: number;
  countCorrect: number;
  pointsCorrect: number;
  indexInSubCategory?: string;
}

export type CandidateT = {
  id: number;
  customerId: number;
  professionId: number;
  professionName?: string;
  professionTarget?: number;
  professionExpiredAt?: Date;
  testId?: string;
  testDate?: Date;
  email: string;
  firstname: string;
  lastname: string;
  gender?: string;
  street?: string;
  streetno?: string;
  zipcode?: string;
  city?: string;
  country?: string;
  phone?: string;
  createdAt: Date;
  uuid: string;
  comment?: string;
  nationality?: string;
  schoolType?: string;
  schoolGraduation?: string;
  dateBirth?: Date | null;
  results?: CandidateResultsT;
  logs?: CandidateLogsT[];
  mailId?: number;
  mailLogs?: MailLogsT[];
  testPending?: boolean;
  resultSumPercent?: number;
  candidateSettingsId?: number;
  candidateSettings?: any;
};

export type SchoolGraduationT = {
  id: number;
  name: string;
};
export interface SchoolGraduationDataT extends SchoolGraduationT {}

export const data2results = (
  data: CandidateResultsDataT
): CandidateResultsT => {
  checkApiResult("data2results", data, {
    mainCategories: "array",
    countCorrect: "number",
    sumPointsCorrect: "number",
    countTotal: "number",
    sumPoints: "number",
    concentrationCorrect: "number",
    concentrationPointsCorrect: "number",
    concentrationTotal: "number",
    concentrationPoints: "number",
  });

  data.mainCategories.forEach((d) =>
    checkApiResult("data2results", d, {
      subCategories: "array",
      countCorrect: "number",
      sumPointsCorrect: "number",
      countTotal: "number",
      sumPoints: "number",
    })
  );

  return {
    ...data,
    mainCategories: data.mainCategories.map((m) => ({
      ...m,
      mainCategoryId: m.mainCategoryId,
      mainCategoryName: m.mainCategoryName,
      subCategories: m.subCategories.map((s) => ({
        ...s,
        subCategoryId: s.subCategoryId,
        subCategoryName: s.subCategoryName,
        questions: s.questions
          ? s.questions.map((q) => data2questionresult(q))
          : [],
      })),
    })),
  };
};

const data2candidate = (data: CandidateDataT): CandidateT => {
  checkApiResult("data2candidate", data, {
    id: "number",
    customerId: "number",
    email: "string",
    firstname: "string",
    lastname: "string",
    gender: "string",
    uuid: "string",
    street: "string",
    streetno: "string",
    zipcode: "string",
    city: "string",
    country: "string",
    phone: "string",
    createdAt: "string",
  });

  const candidate: any = {
    gender: data.gender || "",
  };
  [
    "id",
    "customerId",
    "email",
    "firstname",
    "lastname",
    "uuid",
    "street",
    "streetno",
    "zipcode",
    "city",
    "country",
    "phone",
    "professionId",
    "professionName",
    "professionTarget",
    "testId",
  ].forEach((f) => (candidate[f] = data[f as keyof CandidateDataT] || ""));

  candidate.createdAt = new Date(data.createdAt);
  candidate.testDate = data.testDate ? new Date(data.testDate as string) : null;

  if (data.dateBirth) candidate.dateBirth = new Date(data.dateBirth);
  if (data.professionExpiredAt)
    candidate.professionExpiredAt = new Date(data.professionExpiredAt);
  if (data.schoolGraduation) candidate.schoolGraduation = data.schoolGraduation;
  if (data.schoolType) candidate.schoolType = data.schoolType;
  if (data.comment) candidate.comment = data.comment || "";
  if (data.nationality) candidate.nationality = data.nationality || "";
  if (data.testPending) candidate.testPending = data.testPending;

  if (data["results"]) {
    candidate["results"] = data2results(data.results);
  }

  if (data["logs"]) {
    data["logs"].forEach((log) =>
      checkApiResult("data2candidate_logs", log, {
        type: "string",
        createdAt: "string",
      })
    );

    candidate["logs"] = data.logs.map((l) => ({
      type: l.type,
      createdAt: new Date(l.createdAt),
    }));
  }
  if (data["mailLogs"]) {
    data["mailLogs"].forEach((log) =>
      checkApiResult("data2candidate_mailLogs", log, {
        candidateId: "number",
        mailTemplateId: "number",
        createdAt: "string",
      })
    );
    candidate["mailLogs"] = data.mailLogs.map((m) => ({
      ...m,
      createdAt: new Date(m.createdAt),
    }));
  }

  candidate["candidateSettingsId"] = data.id;

  if (data["candidateSettings"]) {
    candidate["candidateSettings"] = data["candidateSettings"];
  }

  return candidate as CandidateT;
};

const data2candidates = (data: CandidateDataT[]): CandidateT[] => {
  return data.map((d) => data2candidate(d));
};

const candidate2data = (candidate: CandidateT): CandidateDataT => {
  const data: any = {};
  [
    "id",
    "email",
    "firstname",
    "lastname",
    "uuid",
    "street",
    "streetno",
    "zipcode",
    "city",
    "country",
    "phone",
  ].forEach((f) => (data[f] = candidate[f as keyof CandidateT]));
  data["professionId"] = candidate.professionId || 0;
  data["gender"] = candidate.gender || "";
  data["schoolType"] = candidate.schoolType || "";
  if (candidate.dateBirth)
    data["dateBirth"] = JSON.stringify(candidate.dateBirth);
  else data["dateBirth"] = "";
  data["schoolGraduation"] = candidate.schoolGraduation || "";
  data["comment"] = candidate.comment || "";
  data["nationality"] = candidate.nationality || "";
  console.log("candidate2data", candidate, data);
  return data as CandidateDataT;
};

const candidates2data = (candidates: CandidateT[]): CandidateDataT[] => {
  return candidates.map((candidate) => candidate2data(candidate));
};

const data2questionresult = (data: QuestionResultsDataT): QuestionResultsT => {
  checkApiResult("data2questionresult", data, {
    professionId: "number",
    correct: "string",
    correctDecoded: "array",
    points: "number",
    candidateAnswer: "string",
    id: "number",
    example: "number",
    type: "string",
    rating: "string",
    difficulty: "number",
    duration: "number",
    question: "string",
    questionDecoded: "string",
    answerOptions: "string",
    answerOptionsDecoded: "object",
    mainCategoryName: "string",
    mainCategoryId: "number",
    subCategoryName: "string",
    subCategoryId: "number",
    layout: "string",
    evaluation: "string",
  });

  return {
    id: data.id,
    professionId: data.professionId,
    mainCategoryId: data.mainCategoryId,
    mainCategoryName: data.mainCategoryName,
    subCategoryId: data.subCategoryId,
    subCategoryName: data.subCategoryName,
    subCategoryMemText: data.subCategoryMemText,
    subCategoryMemTime: data.subCategoryMemTime,
    type: data.type,
    difficulty: data.difficulty,
    rating: data.rating,
    duration: data.duration,
    question: data.question,
    questionDecoded: data.questionDecoded,
    answerOptions: data.answerOptions,
    answer: data.answer,
    answerDecoded: data.answerDecoded,
    candidateAnswer: data.candidateAnswer,
    candidateAnswerDecoded: data.candidateAnswerDecoded,
    correct: data.correct,
    correctDecoded: data.correctDecoded.map((e) => parseInt(e)),
    points: data.points,
    pointsCorrect: data.pointsCorrect,
    countTotal: data.countTotal,
    countCorrect: data.countCorrect,
    layout: data.layout,
    evaluation: data.evaluation,
    example: data.example ? true : false,
    answerOptionsDecoded: data.answerOptionsDecoded,
    isImage: data.isImage ? true : false,
  };
};

/** ************************************************************************
 *
 *
 */
export const fetchCandidates = async (
  role?: string
): Promise<CallApiResultT<CandidateT[]>> => {
  console.log("%capi.ts line:432 fetchCandidates", "color: #007acc;");
  const res = await get(APIPATH, role ? { role } : {});
  console.log("%capi.ts line:434 res", "color: #007acc;", res);
  if (!res.success || !(res.data && isArray(res.data))) {
    throw Error("[fetchCandidates] " + res.error);
  }
  return {
    success: true,
    error: "",
    status: res.status,
    data: data2candidates(res.data),
  };
};

/** ************************************************************************
 *
 * @returns addedCandidate
 */
export const addCandidate = async (
  Candidate: CandidateT
): Promise<CallApiResultT<CandidateT>> => {
  const resTmp = await post<CandidateDataT>(APIPATH, candidate2data(Candidate));

  const res = {
    ...resTmp,
    data: resTmp.data ? data2candidate(resTmp.data) : undefined,
  };
  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const updateCandidate = async (
  candidate: CandidateT
): Promise<CallApiResultT<CandidateT>> => {
  console.log("updateCandidate", candidate);

  const res = await put<CandidateT>(
    `${APIPATH}/${candidate.id}`,
    candidate2data(candidate)
  );

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const updateCandidateBulk = async (
  candidates: CandidateT[]
): Promise<CallApiResultT<number | number[]>> => {
  console.log("updateCategory", candidates, candidates2data(candidates));

  const res = await put<number | number[]>(
    APIPATH,
    candidates2data(candidates)
  );

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const deleteCandidate = async (
  id: number
): Promise<CallApiResultT<number>> => {
  const res = await del<number>(`${APIPATH}/${id}`);

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const deleteCandidates = async (
  candidateIds: number[]
): Promise<CallApiResultT<number>> => {
  const res = await del<number>("/candidate-bulk", { candidateIds });

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
interface DeleteCandidatesByProfessionPropsI {
  email: string;
  password: string;
  professionId: number;
}
export const deleteCandidatesByProfession = async ({
  email,
  password,
  professionId,
}: DeleteCandidatesByProfessionPropsI): Promise<CallApiResultT<number>> => {
  const res = await del<number>("/candidate", {
    email: email,
    password: password,
    professionId: professionId,
  });

  return res;
};

/** ************************************************************************
 *
 * @returns status
 */
export const sendCandidateLink = async (
  id: number
): Promise<CallApiResultT<number>> => {
  const res = await get<number>(`/candidate-link/${id}`);

  return res;
};

/** ************************************************************************
 *
 */
export const fetchSchoolGraduations = async (
  id: number
): Promise<CallApiResultT<SchoolGraduationT[]>> => {
  const res = await get<SchoolGraduationDataT[]>("/schoolgraduations/");

  return res;
};

/** ************************************************************************
 *
 */
export const exportResult = async (
  type: string,
  data: any
): Promise<CallApiResultT<string | Blob>> => {
  const res =
    type === "CSV"
      ? await post<string>("/export-csv", { data })
      : await post<string>(
          "/export-xls",
          { data },
          {},
          {
            responseType: "blob",
          }
        );

  return res;
};

export const fetchCandidate = async (
  id: number
): Promise<CallApiResultT<CandidateT>> => {
  const res = await get<CandidateDataT>(`${APIPATH}/${id}`);

  if (!res.success || !res.data) {
    throw Error("[fetchCandidate] " + res.error);
  }
  return {
    success: true,
    error: "",
    status: res.status,
    data: data2candidate(res.data),
  };
};

type PostCheckUploadCandidatesResultT = {
  candidates: CandidateDataT[];
  filename: string;
  errors: string[][];
  dups: string[][];
  intraDups: string[];
};
type CheckUploadCandidatesResultT = {
  candidates: CandidateT[];
  filename: string;
  errors: string[][];
  dups: string[][];
  intraDups: string[];
};

/** ************************************************************************
 *
 * @returns
 */
export const checkUploadCandidates = async (
  data: any
): Promise<CallApiResultT<CheckUploadCandidatesResultT>> => {
  const res = await post<PostCheckUploadCandidatesResultT>(
    "/candidate-check-upload",
    data,
    {
      "Content-Type": "multipart/form-data",
    }
  );

  console.log("checkUploadCandidates", res);

  if (res.status === 422) {
    return {
      success: false,
      status: res.status,
      error: res.error,
      errors: res.errors,
    };
  }
  if (!res.success || !res.data) {
    throw Error("[uploadCandidates] " + res.error);
  }

  /** Attention: also 200 can mean, data is not valid, we have to check data.errors */
  return {
    ...res,
    data: {
      candidates: data2candidates(res.data.candidates),
      filename: res.data.filename,
      errors: res.data.errors ? res.data.errors : [],
      dups: res.data.dups ? res.data.dups : [],
      intraDups: res.data.intraDups ? res.data.intraDups : [],
    },
  };
};

type PostUploadCandidatesResultT = {
  candidates: CandidateDataT[];
  professions: ProfessionDataT[];
  transctionsFinished: boolean;
};
type CheckCandidatesResultT = {
  candidates: CandidateT[];
  professions: ProfessionT[];
  transctionsFinished: boolean;
};

/** ************************************************************************
 *
 * @returns
 */
export const uploadCandidates = async (
  filename: string,
  professionId: number,
  dupStrategy: string | null,
  invalidStrategy: string | null,
  intraDupStrategy: string | null
): Promise<CallApiResultT<CheckCandidatesResultT>> => {
  const res = await post<PostUploadCandidatesResultT>(
    "/candidate-upload",
    { filename, dupStrategy, invalidStrategy, intraDupStrategy, professionId },
    {
      "Content-Type": "multipart/form-data",
    }
  );

  console.log("uploadCandidates", res);

  if (res.status === 422) {
    return {
      success: false,
      status: res.status,
      error: res.error,
      errors: res.errors,
    };
  }
  if (!res.success || !res.data) {
    throw Error("[uploadCandidates] " + res.error);
  }

  return {
    ...res,
    data: {
      candidates: data2candidates(res.data.candidates),
      professions: data2professions(res.data.professions),
      transctionsFinished: res.data.transctionsFinished,
    },
  };
};

export const updateCandidateSettings = async (
  candidateId: number,
  percent: number,
  pause: number
): Promise<CallApiResultT<number>> => {
  const res = await put<number>(`/candidate-settings/${candidateId}`, {
    compensationPercent: percent,
    compensationPause: pause,
  });

  return res;
};

export const deleteAllCandidateSettings = async (): Promise<
  CallApiResultT<number>
> => {
  const res = await del<number>("/candidate-settings");

  return res;
};
