import React, { Fragment } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";

import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import BuildIcon from "@mui/icons-material/Build";
import ExitToApp from "@mui/icons-material/ExitToApp";
import GroupIcon from "@mui/icons-material/Group";
import MusicVideoIcon from "@mui/icons-material/MusicVideo";
import QuizIcon from "@mui/icons-material/Quiz";
import PersonIcon from "@mui/icons-material/Person";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import ViewListIcon from "@mui/icons-material/ViewList";
import HelpIcon from "@mui/icons-material/Help";
import ViewCompactIcon from "@mui/icons-material/ViewCompact";
import SettingsIcon from "@mui/icons-material/Settings";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import HomeIcon from "@mui/icons-material/Home";
import BarChartIcon from "@mui/icons-material/BarChart";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { Tooltip } from "../../shared/forms/Tooltip";

export const Sidebar = () => {
  return (
    <Fragment>
      <List>
        <SidebarEntry
          to="/admin/dashboard"
          icon={<HomeIcon />}
          text="Dashboard"
        />
        <SidebarEntry to="/admin/admin" icon={<PersonIcon />} text="Admins" />
        <SidebarEntry to="/admin/customer" icon={<GroupIcon />} text="Kunden" />
      </List>
      <Divider />
      <List>
        <SidebarEntry
          to="/admin/professions"
          icon={<BuildIcon />}
          text="Berufe"
        />
        <SidebarEntry
          to="/admin/maincategories"
          icon={<ViewAgendaIcon />}
          text="Hauptkategorien"
        />

        <SidebarEntry
          to="/admin/subcategories"
          icon={<ViewListIcon />}
          text="Unterkategorien"
        />

        <SidebarEntry to="/admin/question" icon={<HelpIcon />} text="Fragen" />

        <SidebarEntry
          to="/admin/relations"
          icon={<ViewCompactIcon />}
          text="Zuordnungen"
        />

        <SidebarEntry
          to="/admin/media"
          icon={<MusicVideoIcon />}
          text="Medien"
        />

        <SidebarEntry
          to="/admin/mailtemplates"
          icon={<ContactMailIcon />}
          text="Mail-Templates"
        />
        <SidebarEntry to="/admin/help" icon={<QuizIcon />} text="FAQs" />
      </List>
      <Divider />
      <List>
        <SidebarEntry
          to="/admin/orders"
          icon={<ListAltIcon />}
          text="Bestellungen"
        />
        <SidebarEntry
          to="/admin/stats"
          icon={<BarChartIcon />}
          text="Statistik"
        />
      </List>
      <Divider />

      <List>
        <SidebarEntry
          to="/admin/settings"
          icon={<SettingsIcon />}
          text="Settings"
        />
        <SidebarEntry to="/admin/logout" icon={<ExitToApp />} text="Logout" />
      </List>
    </Fragment>
  );
};

interface SidebarEntryPropsI {
  to: string;
  text: string;
  icon: React.ReactNode;
}

const SidebarEntry = ({ to, text, icon }: SidebarEntryPropsI) => (
  <Link
    component={RouterLink}
    to={to}
    underline="none"
    sx={{
      color: "text.secondary",
    }}
  >
    <ListItemButton
      sx={{
        backgroundColor: useLocation().pathname === to ? "lightgray" : "white",
      }}
    >
      <Tooltip title={text}>
        <ListItemIcon>{icon}</ListItemIcon>
      </Tooltip>
      <ListItemText primary={text} />
    </ListItemButton>
  </Link>
);
